import React from "react"
import FormAudio from "../components/modules/forms/formAudio/FormAudio"

const audio = () => {
  return (
    <div className="wrapper">
      <FormAudio />
    </div>
  )
}

export default audio
